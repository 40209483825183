import { Divider } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Alert, Typography } from 'antd';
import { SchemaActionFormField, SchemaActionSection } from './types';

export const getSchemaActionVersion = (schemaAction: any): number => {
  const definition = schemaAction?.definition;
  if (definition?.version) {
    return definition.version;
  } else {
    return 1;
  }
};

// We will maintain progress bar only for V2 schema actions and higher
export const schemaActionHasProgressBar = (schemaAction: any | undefined): boolean => {
  if (schemaAction && getSchemaActionVersion(schemaAction) === 2) {
    return schemaAction.definition?.settings?.showProgressBar ? true : false;
  } else {
    return false;
  }
};

export const getSchemaActionFormFieldDefaultValue = (
  formField: any,
  sourceRecord: DbRecordEntityTransform | null,
): any => {
  if (formField.defaultValue && sourceRecord) {
    let defaultValue: any = formField.defaultValue;

    if (String(formField.defaultValue).includes('source_record_properties')) {
      const propertyName = formField.defaultValue.split('_').pop()?.replace('}', '');

      // value should be taken from the source record properties
      if (propertyName) {
        if (sourceRecord?.properties) {
          defaultValue = sourceRecord.properties[propertyName] || undefined;
        }
      }
    }
    // value should be taken from the source record
    else if (String(formField.defaultValue).includes('source_record')) {
      const propertyName = formField.defaultValue.split('_').pop()?.replace('}', '');
      if (propertyName) {
        if (sourceRecord) {
          defaultValue = sourceRecord[propertyName] || undefined;
        }
      }
    }

    return defaultValue;
  }
};

interface SchemaActionFieldPayload {
  SchemaAction: SchemaActionEntity | undefined;
  Form: any;
  fieldName: string;
  schemaColumns: SchemaColumnEntity[];
}

export const constructSchemaActionAuxElement = (field: SchemaActionFormField) => {
  const options = field.options || {};

  switch (field.type) {
    case 'alert':
      return (
        <Alert
          style={{ marginBottom: 15, marginTop: 7 }}
          message={options.message || undefined}
          description={options.description || undefined}
          type={options.type || 'info'}
        />
      );
    case 'orderedList':
      return (
        <ol className="odinFormOrderedList">
          {options.items?.map((item: string) => (
            <li key={item}>{item}</li>
          ))}
        </ol>
      );
    case 'text':
      return <p>{options.contents || 'No text provided.'}</p>;
    case 'divider':
      return <Divider style={{ marginTop: 5, marginBottom: 13 }} />;
    case 'title':
      return (
        <div style={{ paddingBottom: 12 }}>
          <Typography.Title
            level={!options?.level || options?.level > 6 ? 3 : options?.level}
            style={{ marginTop: 8 }}
          >
            {options.contents || 'No title contents provided'}
          </Typography.Title>
        </div>
      );
    default:
      return <></>;
  }
};

// Try to get label from the schema columns with the field value itself
const getSchemaOptionLabelFromValue = (
  value: string,
  fieldName: string,
  schemaColumns: any[],
): string | undefined => {
  let label: string | undefined = undefined;
  const schemaOption = schemaColumns.find(
    (column: SchemaColumnEntity) => column.name === fieldName,
  );

  if (schemaOption) {
    const option = schemaOption.options?.find((option: any) => option.value === value);
    label = option?.label || undefined;
  }

  return label;
};

export const constructSchemaActionFormField = (payload: SchemaActionFieldPayload) => {
  const { SchemaAction, Form, fieldName, schemaColumns } = payload;
  const Definition = SchemaAction?.definition;
  const version = getSchemaActionVersion(SchemaAction);
  let FormField: any = undefined;

  // Get all form fields from all sections. Note that V1 and V2 schema action definitions have
  // form fields in different location, hence this separate logic.
  let allFormFields: SchemaActionFormField[] = [];
  if (version === 1) {
    allFormFields = Definition?.formFields || [];
  } else {
    Definition?.formDefinition.map((section: SchemaActionSection) => {
      allFormFields = allFormFields.concat(section.formFields);
    });
  }

  if (SchemaAction && Form && Definition && fieldName) {
    FormField = {} as SchemaActionFormField;
    let Field = undefined;

    if (allFormFields.length > 0) {
      Field = allFormFields.find((field: any) => field.name === fieldName);
    }

    if (Field) {
      const FieldDefinition = Field;
      FormField.required = Field.required || undefined;
      FormField.name = fieldName;
      FormField.defaultValue = Field.defaultValue || undefined;
      FormField.divided = Field.divided || undefined;

      // Certain LOOKUP fields can have custom filter that depends on other field value. Like, searching for a user that belongs to a certain team.
      if (Field.useCustomLookupFilter) {
        FormField.customFilterValue =
          Form.getFieldValue(Field.useCustomLookupFilter?.field) || null;
        FormField.customFilterEntity = Field.useCustomLookupFilter?.entity || undefined;
      }

      // Watch for visibility overrides that can be defined in the schema action definition and set in relation to other field's value. In this case we will set the hidden prop to true, and it will be excluded from the form + validation.
      if (FieldDefinition.visibleWhen) {
        FieldDefinition.visibleWhen?.forEach((visibleWhen: any) => {
          const { field, value } = visibleWhen;
          FormField.hidden = true;
          value.map((val: any) => {
            if (Form.getFieldValue(field) === val || Form.getFieldValue(field)?.indexOf(val) > -1) {
              FormField.hidden = false;
            }
          });
        });
      }

      // Allow having always invisible field, but not if controlled by invisibleWhen. The reason for this is that we do not want to put invisible fields in the form that are either controlled or required by other fields.
      if (FieldDefinition.alwaysInvisible && !FieldDefinition.visibleWhen && !Field.required) {
        FormField.alwaysInvisible = true;
      }

      // Watch for ENUM options. Schema actions can define which options we show in ENUM select fields
      if (FieldDefinition.enumOptions) {
        FormField.options = FieldDefinition.enumOptions?.map((option: string) => ({
          label:
            getSchemaOptionLabelFromValue(option, FieldDefinition.name, schemaColumns) || option,
          value: option,
          name: option,
        }));
      }

      // Watch for ENUM filters. Schema actions can define when to show certain ENUM options, depending
      // on the value of another field.
      if (FieldDefinition?.enumOptionFilter) {
        FieldDefinition?.enumOptionFilter?.forEach(
          (enumFilter: { field: string; value: string[]; options: string[] }) => {
            const { field, value, options } = enumFilter;
            if (value?.indexOf(Form.getFieldValue(field)) > -1) {
              FormField.options = options?.map((option: string) => ({
                label:
                  getSchemaOptionLabelFromValue(option, FieldDefinition.name, schemaColumns) ||
                  option,
                value: option,
                name: option,
              }));
            }
          },
        );
      }
    }
  }
  return FormField;
};

export const shouldSchemaActionShowTitleField = (schemaAction: SchemaActionEntity): boolean => {
  if (schemaAction) {
    const { definition } = schemaAction;
    return definition?.showRecordTitle || definition?.settings?.showRecordTitle;
  } else {
    return false;
  }
};

export const shouldSchemaActionShowRecordTypeField = (
  schemaAction: SchemaActionEntity,
  schema: SchemaEntity,
): boolean => {
  if (schema?.types!.length! === 1 && schema?.types[0]!.name === 'DEFAULT') {
    return false;
  }

  if (schemaAction) {
    const { definition } = schemaAction;
    return definition?.showRecordType || definition?.settings?.showRecordType;
  } else if (!schemaAction && schema?.types?.length! > 0) {
    return true;
  } else {
    return false;
  }
};

export const shouldSchemaActionShowRecordNumberField = (
  schemaAction: SchemaActionEntity,
): boolean => {
  if (schemaAction) {
    const { definition } = schemaAction;
    return definition?.overrideRecordNumber || definition?.settings?.overrideRecordNumber;
  } else {
    return false;
  }
};
