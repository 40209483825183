import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import * as Sentry from '@sentry/react';
import { Layout, Result } from 'antd';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import './App.scss';
import CacheBuster from './CacheBuster';
import PlanningModuleMap from './com.netomnia/modules/ProjectModule/Map';
import DataSetAssign from './containers/CrmModule/containers/DataSet/DataSetAssign';
import { SalesLeaderBoard } from './containers/CrmModule/containers/SalesLeaderBoard';
import { TelesalesDashboard } from './containers/CrmModule/containers/TelesalesDashboard';
import { DashboardModuleRoutes } from './containers/Dashboard/DashboardModuleNavigatoinMenu';
import { ServiceAppointmentCalendarV2 } from './containers/FieldServiceModule/containers/ServiceAppointmentCalendarV2';
import HomeModuleRoutes from './containers/Home/HomeModuleNavigation';
import MFASettings from './containers/IdentityManagerModule/containers/MFASettings';
import Login from './containers/IdentityManagerModule/containers/UserLogin';
import ForgotPassword from './containers/IdentityManagerModule/containers/UserLogin/containers/ForgotPassword';
import LoginModal from './containers/IdentityManagerModule/containers/UserLogin/containers/LoginModal';
import Register from './containers/IdentityManagerModule/containers/UserLogin/containers/Register';
import ResetPassword from './containers/IdentityManagerModule/containers/UserLogin/containers/ResetPassword';
import { IdentityManagerModuleRoutes } from './containers/IdentityManagerModule/IdentityManagerModuleNavigationMenu';
import { IdentityModuleRoutes } from './containers/IdentityModule/IdentityModuleNavigationMenu';
import { MergeModuleRoutes } from './containers/Merge/MergeModuleNavigation';
import Navigation from './containers/Navigation/Navigation';
import { NotificationModuleRoutes } from './containers/NotificationModule/NotificationModuleNavigationMenu';
import OrderItemReplaceProductView from './containers/OrderModule/containers/OrderItem/OrderItemReplaceProductView';
import { ProductModuleRoutes } from './containers/ProductModule/ProductModuleNavigation';
import ProjectModuleCableConnectionDetailViewWide from './containers/ProjectModule/components/ProjectModuleCableConnectionDetailViewWide';
import ProjectModuleRecordDetailViewWide from './containers/ProjectModule/components/ProjectModuleRecordDetailViewWide';
import OpenreachNetworkAdjustmentDetail from './containers/ProjectModule/containers/OpenreachNetworkAdjustmentDetail';
import SchemaActionDetailView from './containers/SchemaManagerModule/containers/SchemaActions/SchemaActionDetailView';
import SchemasDetailView from './containers/SchemaManagerModule/containers/Schemas/DetailView';
import SchemaListView from './containers/SchemaManagerModule/containers/Schemas/ListView';
import WorkflowDetailView from './containers/SchemaManagerModule/containers/Workflow/DetailView';
import Search from './containers/Search/Search';
import { ServiceModuleRoutes } from './containers/ServiceModule/ServiceModuleNavigation';
import OdinSettings from './containers/Settings';
import SupportDrawer from './containers/SupportDrawer/SupportDrawer';
import { SupportModuleRoutes } from './containers/SupportModule/SupportModuleNavigation';
import OdinHelmet from './core/navigation/OdinHelmet';
import ProtectedRoute from './core/navigation/ProtectedRoute';
import RoleBasedProtectedRoute from './core/navigation/RoleBasedProtectedRoute';
import FileListFeedView from './core/records/components/ListView/FileListFeedView';
import { setDbRecordState, toggleSearchVisibility } from './core/records/store/actions';
import ProcessWorkflow from './core/workflowEngine/components/ProcessWorkflow';
import Error403 from './shared/pages/403';
import Error404 from './shared/pages/404';
import Error500 from './shared/pages/500';
import AppLoadingPage from './shared/pages/AppLoading';
import AppUnavailable from './shared/pages/AppUnavailable';
import {
  canUserAccessDesktopApp,
  isExternalUser as checkExternalUser,
  isUserAuthenticated,
  isUserTokenExpired,
} from './shared/permissions/rbacRules';
import HotKeyWrapper from './shared/system/hotkeys';
import Message from './shared/system/messages';
import Notification from './shared/system/notifications';
import history from './shared/utilities/browserHistory';
import { getEnvironmentName } from './shared/utilities/identityHelpers';
import BillingRequestDetailView from './v2/modules/BillingModule/views/BillingRequestDetailView';
import CreditNoteDetailView from './v2/modules/BillingModule/views/CreditNoteDetailView';
import InvoiceDetailView from './v2/modules/BillingModule/views/InvoiceDetailView';
import InvoiceItemDetailView from './v2/modules/BillingModule/views/InvoiceItemDetailView';
import PaymentMethodDetailView from './v2/modules/BillingModule/views/PaymentMethodDetailView';
import PaymentMethodRefundDetailView from './v2/modules/BillingModule/views/PaymentMethodRefundDetailView';
import TransactionDetailView from './v2/modules/BillingModule/views/TransactionDetailView';
import AccountDetailView from './v2/modules/CrmModule/views/AccountDetailView';
import AddressDetailView from './v2/modules/CrmModule/views/AddressDetailView';
import ContactDetailView from './v2/modules/CrmModule/views/ContactDetailView';
import ContactIdentityDetailView from './v2/modules/CrmModule/views/ContactIdentityDetailView';
import CrmDataSetDetailView from './v2/modules/CrmModule/views/CrmDataSetDetailView';
import LeadDetailView from './v2/modules/CrmModule/views/LeadDetailView';
import VisitDetailView from './v2/modules/CrmModule/views/VisitDetailView';
import PermissionToFailDetailView from './v2/modules/FieldServiceModule/views/PermissionToFailDetailView';
import ServiceAppointmentConfigDetailView from './v2/modules/FieldServiceModule/views/ServiceAppointmentConfigDetailView';
import WorkOrderDetailView from './v2/modules/FieldServiceModule/views/WorkOrderDetailView';
import FileDetailView from './v2/modules/FileModule/views/FileDetailView';
import UserSync from './v2/modules/IdentityModule/UserSync';
import OrderBuilder from './v2/modules/OrderModule/OrderBuilder';
import BillingAdjustmentDetailView from './v2/modules/OrderModule/views/BillingAdjustmentDetailView';
import ChurnRequestDetailView from './v2/modules/OrderModule/views/ChurnRequestDetailView';
import OrderDetailView from './v2/modules/OrderModule/views/OrderDetailView';
import PICRequestDetailView from './v2/modules/OrderModule/views/PICRequestDetailView';
import ApplicationForPaymentDetailView from './v2/modules/ProjectModule/views/ApplicationForPaymentDetailView';
import ExchangeDetailView from './v2/modules/ProjectModule/views/ExchangeDetailView';
import FeatureDetailView from './v2/modules/ProjectModule/views/FeatureDetailView';
import FeaturePriceEntryDetailView from './v2/modules/ProjectModule/views/FeaturePriceEntryDetailView';
import PaymentNoticeDetailView from './v2/modules/ProjectModule/views/PaymentNoticeDetailView';
import ProgramDetailView from './v2/modules/ProjectModule/views/ProgramDetailView';
import ProjectDetailView from './v2/modules/ProjectModule/views/ProjectDetailView';
import RegionDetailView from './v2/modules/ProjectModule/views/RegionDetailView';
import { RFCManagementView } from './v2/modules/ProjectModule/views/RFCManagementView';
import WorkListDetailView from './v2/modules/ProjectModule/views/WorkListDetailView';
import ViewConfigurationDetailView from './v2/modules/SchemaManagerModule/views/ViewConfigurationDetailView';
import { DownloadRecordPdf } from './v2/shared/core/DownloadRecordPdf';
import DefaultDetailView from './v2/shared/views/DetailView';

import Autosplicing from './com.netomnia/modules/ProjectModule/Autosplicing';
import VendorDashboard from './com.netomnia/modules/ProjectModule/VendorDashboard';
import BillingModuleDashboard from './containers/BillingModule/containers/Dashboard';
import AppointmentsCalendar from './containers/CrmModule/containers/Appointments/AppointmentsCalendar';
import CrmModuleDashboard from './containers/CrmModule/containers/Dashboard';
import DataSetBuild from './containers/CrmModule/containers/DataSet/DataSetBuild';
import DataSetWork from './containers/CrmModule/containers/DataSet/DataSetWork';
import FieldServiceModuleDashboard from './containers/FieldServiceModule/containers/Dashboard';
import OrderModuleDashboard from './containers/OrderModule/containers/Dashboard';
import InvoiceBuilder from './containers/OrderModule/containers/Order/InvoiceBuilder';
import ProjectModuleApplicationForPaymentApprovals from './containers/ProjectModule/components/ProjectModuleApplicationForPaymentApprovals';
import BuildPackV2 from './containers/ProjectModule/containers/BuildPackV2';
import ProjectModuleDashboard from './containers/ProjectModule/containers/Dashboard';
import ClosureConfigurator from './containers/ProjectModule/containers/Feature/ClosureConfigurator';
import JSONGraphBrowser from './containers/ProjectModule/containers/JSONGraphBrowser';
import BulkBuildComplete from './containers/ProjectModule/containers/Openreach/BulkBuildComplete';
import Inventory from './containers/ProjectModule/containers/Openreach/Inventory';
import Noi from './containers/ProjectModule/containers/Openreach/NoticeOfIntent';
import DetailViewContext from './core/records/components/DetailViewContext';
import RecordListView from './core/records/components/ListView';
import RawDataDrawer from './core/records/components/RawData/RawDataDrawer';
import WorkOrderRemediationView from './v2/modules/FieldServiceModule/views/WorkOrderRemediationView';
import RecordDrawer from './v2/shared/components/RecordDrawer';
import Inbox from './v2/shared/views/Inbox';

const { Header } = Layout;

const {
  ORDER,
  ORDER_ITEM,
  PRODUCT,
  PROGRAM,
  PROJECT,
  REGION,
  WORK_ORDER,
  ACCOUNT,
  ADDRESS,
  CONTACT,
  LEAD,
  CONTACT_IDENTITY,
  INVOICE,
  INVOICE_ITEM,
  TRANSACTION,
  PAYMENT_METHOD,
  CREDIT_NOTE,
  BILLING_REQUEST,
  BILLING_ADJUSTMENT,
  SERVICE_APPOINTMENT_CONFIG,
  CUSTOMER_DEVICE_ONT,
} = SchemaModuleEntityTypeEnums;
const {
  SCHEMA_MODULE,
  PRODUCT_MODULE,
  PROJECT_MODULE,
  FIELD_SERVICE_MODULE,
  CRM_MODULE,
  ORDER_MODULE,
  BILLING_MODULE,
  SERVICE_MODULE,
} = SchemaModuleTypeEnums;

const EXCHANGE = 'Exchange';
const PAYMENT_METHOD_REFUND = 'PaymentMethodRefund';

const SENTRY_TESTING = false;

interface Props {
  userReducer: any;
  toggleSearchVisibility: any;
  modifyDbRecordState: Function;
}

interface State {
  odinSettingsVisible: boolean;
  supportModalVisible: boolean;
  isIdleUser: boolean;
}

class App extends React.Component<Props, State> {
  sessionExpiryTimer: NodeJS.Timeout | undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      odinSettingsVisible: false,
      supportModalVisible: false,
      isIdleUser: false,
    };
  }

  toggleOdinSettingsModal = () => {
    this.setState({ odinSettingsVisible: !this.state.odinSettingsVisible });
  };

  toggleSupportModal = () => {
    this.setState({ supportModalVisible: !this.state.supportModalVisible });
  };

  /* Set Menu Colors for Netomnia or YouFibre */
  setMenuStyle() {
    const { userReducer } = this.props;
    if (
      userReducer.user &&
      userReducer.user.organization.id === '8c96572c-eee6-4e78-9e3f-8c56c5bb9242'
    ) {
      return 'netomnia-theme-menu';
    } else {
      return 'youfibre-theme-menu';
    }
  }

  componentDidMount() {
    const { userReducer } = this.props;

    // Added this line to stop the body from moving left/right
    document.body.style.overflowX = 'hidden';

    const userToken = localStorage.getItem(`token`);

    // Try to Initialize Sentry if it is not already initialized
    if (this.props.userReducer && !Sentry.getCurrentHub()?.getClient() && window) {
      this.initializeSentry();
    }

    // Initialize Freshchat on Component mount if userReducer is available
    if (
      userToken &&
      userReducer?.user &&
      import.meta.env.VITE_FRESHCHAT_TOKEN &&
      import.meta.env.VITE_FRESHCHAT_HOST
    ) {
      this.initializeFreshchat();
    }
  }

  componentWillUnmount() {
    // Remove the session expiry timer
    if (this.sessionExpiryTimer) {
      clearInterval(this.sessionExpiryTimer);
    }
    this.sessionExpiryTimer = undefined;

    // Unmount the chat widget
    (window as any).fcWidget?.destroy();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    // userReducer might come late into the picture, so we need to check if it is available
    if (
      !prevProps.userReducer?.user &&
      this.props.userReducer?.user &&
      !(window as any)!.fcWidget!.isInitialized()
    ) {
      this.initializeFreshchat();
    }
  }

  initializeFreshchat = () => {
    const { userReducer } = this.props;
    if (window && userReducer) {
      (window as any).fcWidget?.init({
        token: import.meta.env.VITE_FRESHCHAT_TOKEN,
        host: import.meta.env.VITE_FRESHCHAT_HOST,
        externalId: userReducer.user?.id,
        firstName: userReducer.user?.firstname,
        lastName: userReducer.user?.lastname,
        email: userReducer.user?.email,
        config: {
          headerProperty: {
            hideChatButton: true,
            backgroundColor: '#2e598a',
            foregroundColor: '#ffffff',
          },
        },
      });
    }
  };

  // Initialize Sentry only for environments other than localhost or if SENTRY_TESTING is true
  initializeSentry = () => {
    const environment = getEnvironmentName(window);
    if (environment && (environment !== 'localhost' || SENTRY_TESTING)) {
      console.log('%cSentry initialized', 'color: lime');
      Sentry.init({
        dsn: 'https://1809f594ee0f4494b14a9c38f1aa32a9@o916048.ingest.sentry.io/4505239303880704',
        environment: environment,
        integrations: [Sentry.browserTracingIntegration],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        initialScope: {
          user: {
            id: this.props.userReducer?.user?.id,
            email: this.props.userReducer?.user?.email,
            name: `${this.props.userReducer?.user?.firstname} ${this.props.userReducer?.user?.lastname}`,
          },
        },
      });
    }
  };

  blurBackgroundForLoggedOutUsers() {
    const { userReducer } = this.props;
    if (history && history.location && history.location.pathname) {
      return (
        !isUserAuthenticated(userReducer) &&
        isUserTokenExpired() &&
        history.location.pathname.indexOf('login') === -1 &&
        history.location.pathname !== '/forgot-password' &&
        !history.location.pathname.includes('/reset-password') &&
        !history.location.pathname.includes('/register')
      );
    }
  }

  render() {
    const { userReducer } = this.props;

    return (
      <div
        className="app-container"
        style={{
          filter: this.blurBackgroundForLoggedOutUsers() ? 'blur(0.8em)' : '',
        }}
      >
        <Layout className="page-layout">
          <Notification />
          <Message />
          <Router history={history}>
            <UserSync />
            <RecordDrawer />
            <CacheBuster />
            <OdinSettings
              isVisible={this.state.odinSettingsVisible}
              toggleOdinSettingsModal={this.toggleOdinSettingsModal}
            />
            <SupportDrawer
              supportDrawerVisible={this.state.supportModalVisible}
              toggleSupportModal={this.toggleSupportModal}
            />
            <HotKeyWrapper />
            <OdinHelmet />
            {!isUserAuthenticated(userReducer) && isUserTokenExpired() ? <LoginModal /> : <></>}
            {isUserAuthenticated(userReducer) &&
              !checkExternalUser(userReducer) &&
              canUserAccessDesktopApp(userReducer) && (
                <>
                  {/*<SchemaSync />*/}
                  <Search
                    entities={[
                      'CrmModule:Account',
                      'OrderModule:Order',
                      'FieldServiceModule:WorkOrder',
                      'CrmModule:Contact',
                    ]}
                    schema={{
                      id: 'GLOBAL_SEARCH_DRAWER',
                      moduleName: 'SchemaModule',
                      entityName: 'ALL',
                    }}
                    renderStyle="drawer"
                  />
                  <RawDataDrawer />
                  <ProcessWorkflow />
                  <Header className="header">
                    <Navigation
                      toggleOdinSettingsModal={this.toggleOdinSettingsModal}
                      toggleSupportModal={this.toggleSupportModal}
                    />
                  </Header>
                </>
              )}
            {isUserAuthenticated(userReducer) &&
              checkExternalUser(userReducer) &&
              canUserAccessDesktopApp(userReducer) &&
              history.location.pathname !== '/login/mfa' && (
                <>
                  <Header className="header">
                    <Navigation
                      toggleOdinSettingsModal={this.toggleOdinSettingsModal}
                      toggleSupportModal={this.toggleSupportModal}
                    />
                    <Result
                      status="403"
                      title="403"
                      subTitle="Customer portal is under development.. check back later"
                      extra={<></>}
                    />
                  </Header>
                </>
              )}
          </Router>
          <Router history={history}>
            {canUserAccessDesktopApp(userReducer) ? (
              <Switch>
                <Route exact path="/">
                  <HomeModuleRoutes />
                </Route>
                <Route exact path="/Inbox/:selectedMention?">
                  <Inbox />
                </Route>
                <Route path="/NotificationModule">
                  <NotificationModuleRoutes />
                </Route>
                <Route path="/SupportModule">
                  <SupportModuleRoutes />
                </Route>
                <Route path="/ProductModule">
                  <ProductModuleRoutes />
                </Route>
                <Route path="/OrderModule">
                  <Switch>
                    {/* Order Builder */}
                    <ProtectedRoute
                      exact
                      path={`/${ORDER_MODULE}/OrderBuilder/`}
                      moduleName={ORDER_MODULE}
                      entityName={'Order'}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <div style={{ padding: 15 }}>
                            <OrderBuilder />
                          </div>
                        </Suspense>
                      }
                    />

                    <ProtectedRoute
                      exact
                      path={'/OrderModule/Dashboard'}
                      moduleName={ORDER_MODULE}
                      entityName="Dashboard"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <OrderModuleDashboard />
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/OrderModule/Order/:recordId`}
                      moduleName={ORDER_MODULE}
                      entityName="Order"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={ORDER_MODULE} entityName={ORDER}>
                            <OrderDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/OrderModule/BillingAdjustment/:recordId`}
                      moduleName={ORDER_MODULE}
                      entityName={BILLING_ADJUSTMENT}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext
                            moduleName={ORDER_MODULE}
                            entityName={BILLING_ADJUSTMENT}
                          >
                            <BillingAdjustmentDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/OrderModule/ChurnRequest/:recordId`}
                      moduleName={ORDER_MODULE}
                      entityName={'ChurnRequest'}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={ORDER_MODULE} entityName={'ChurnRequest'}>
                            <ChurnRequestDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/OrderModule/PICRequest/:recordId`}
                      moduleName={ORDER_MODULE}
                      entityName={'PICRequest'}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={ORDER_MODULE} entityName={'PICRequest'}>
                            <PICRequestDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />

                    <ProtectedRoute
                      exact
                      path={`/OrderModule/OrderItem/:recordId`}
                      moduleName={ORDER_MODULE}
                      entityName="OrderItem"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={ORDER_MODULE} entityName={ORDER_ITEM}>
                            <DefaultDetailView showCollaborators />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/OrderModule/OrderItem/:recordId/w`}
                      moduleName={ORDER_MODULE}
                      entityName="OrderItem"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={ORDER_MODULE} entityName={ORDER_ITEM}>
                            <OrderItemReplaceProductView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />

                    <ProtectedRoute
                      exact
                      path={`/OrderModule/:entityName`}
                      moduleName={ORDER_MODULE}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <RecordListView moduleName={ORDER_MODULE} />
                        </Suspense>
                      }
                    />

                    <ProtectedRoute
                      exact
                      path={`/OrderModule/:entityName/:recordId`}
                      moduleName={ORDER_MODULE}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={ORDER_MODULE}>
                            <DefaultDetailView showCollaborators />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />

                    <ProtectedRoute
                      exact
                      path={`/:relatedRecordModuleName/:relatedRecordEntityName/:relatedRecordId/related/:dbRecordAssociationId/${PRODUCT_MODULE}/${PRODUCT}/:recordId`}
                      moduleName={PRODUCT_MODULE}
                      entityName={PRODUCT}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={PRODUCT_MODULE} entityName={PRODUCT}>
                            <DefaultDetailView showCollaborators />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />

                    {/* Invoice Builder */}
                    <ProtectedRoute
                      exact
                      path={`/OrderModule/Order/:recordId/invoice`}
                      moduleName={ORDER_MODULE}
                      entityName={ORDER}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <InvoiceBuilder moduleName={ORDER_MODULE} entityName={ORDER} />
                        </Suspense>
                      }
                    />
                  </Switch>
                </Route>
                <Route path="/AuditModule">
                  <Switch>
                    <ProtectedRoute
                      exact
                      path={`/AuditModule/:entityName`}
                      moduleName={'AuditModule'}
                      entityName="ActivityLog"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <RecordListView moduleName={'AuditModule'} />
                        </Suspense>
                      }
                    />

                    <ProtectedRoute
                      exact
                      path={`/AuditModule/:entityName/:recordId`}
                      moduleName={'AuditModule'}
                      entityName="ActivityLog"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={'AuditModule'} entityName={'ActivityLog'}>
                            <DefaultDetailView showCollaborators />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                  </Switch>
                </Route>
                <Route path="/CrmModule">
                  <Switch>
                    <ProtectedRoute
                      exact
                      path={`/CrmModule/Dashboard`}
                      moduleName={CRM_MODULE}
                      entityName="Dashboard"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <CrmModuleDashboard />
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path="/CrmModule/SalesLeaderBoard"
                      moduleName={CRM_MODULE}
                      entityName="SalesLeaderBoard"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <SalesLeaderBoard />
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path="/CrmModule/TelesalesDashboard"
                      moduleName={CRM_MODULE}
                      entityName="TelesalesDashboard"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <TelesalesDashboard />
                        </Suspense>
                      }
                    />

                    <ProtectedRoute
                      exact
                      path={`/${CRM_MODULE}/AppointmentCalendar`}
                      moduleName={CRM_MODULE}
                      entityName="TelesalesDashboard"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <AppointmentsCalendar />
                        </Suspense>
                      }
                    />

                    <ProtectedRoute
                      exact
                      path={`/CrmModule/Visit/:recordId`}
                      moduleName={CRM_MODULE}
                      entityName="Visit"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={CRM_MODULE} entityName="Visit">
                            <VisitDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/CrmModule/Lead/:recordId`}
                      moduleName={CRM_MODULE}
                      entityName="Lead"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={CRM_MODULE} entityName={LEAD}>
                            <LeadDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/CrmModule/Account/:recordId`}
                      moduleName={CRM_MODULE}
                      entityName="Account"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={CRM_MODULE} entityName={ACCOUNT}>
                            <AccountDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/CrmModule/Contact/:recordId`}
                      moduleName={CRM_MODULE}
                      entityName="Contact"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={CRM_MODULE} entityName={CONTACT}>
                            <ContactDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />

                    <ProtectedRoute
                      exact
                      path={`/${CRM_MODULE}/CrmDataset/Assign/`}
                      moduleName={CRM_MODULE}
                      accessPermissions={['crmmodule.crmdataset.assign.read']}
                      component={<DataSetAssign />}
                    />

                    <ProtectedRoute
                      exact
                      path={`/CrmModule/CrmDataSet/:recordId`}
                      moduleName={CRM_MODULE}
                      entityName="CrmDataset"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={CRM_MODULE} entityName={'CrmDataset'}>
                            <CrmDataSetDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/CrmModule/ContactIdentity/:recordId`}
                      moduleName={CRM_MODULE}
                      entityName="ContactIdentity"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={CRM_MODULE} entityName={CONTACT_IDENTITY}>
                            <ContactIdentityDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />

                    <ProtectedRoute
                      exact
                      path={`/CrmModule/Address/:recordId`}
                      moduleName={CRM_MODULE}
                      entityName="Address"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={CRM_MODULE} entityName={ADDRESS}>
                            <AddressDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/:relatedRecordModuleName/:relatedRecordEntityName/:relatedRecordId/related/:dbRecordAssociationId/CrmModule/Contact/:recordId/`}
                      moduleName={CRM_MODULE}
                      entityName="Contact"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={CRM_MODULE} entityName="Contact">
                            <ContactDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />

                    <ProtectedRoute
                      exact
                      path={`/${CRM_MODULE}/:entityName`}
                      moduleName={CRM_MODULE}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <RecordListView moduleName={CRM_MODULE} />
                        </Suspense>
                      }
                    />

                    <ProtectedRoute
                      exact
                      path={`/${CRM_MODULE}/CrmDataset/:recordId/Build`}
                      moduleName={CRM_MODULE}
                      entityName="CrmDataset"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DataSetBuild />
                        </Suspense>
                      }
                    />

                    <ProtectedRoute
                      exact
                      path={`/${CRM_MODULE}/CrmDataset/:recordId/Work`}
                      moduleName={CRM_MODULE}
                      entityName="CrmDataset"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DataSetWork />
                        </Suspense>
                      }
                    />

                    <ProtectedRoute
                      exact
                      path={`/${CRM_MODULE}/:entityName/:recordId`}
                      moduleName={CRM_MODULE}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={CRM_MODULE}>
                            <DefaultDetailView showCollaborators />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                  </Switch>
                </Route>

                {/* Service Module */}
                <Route path="/ServiceModule">
                  <Switch>
                    {/* Service module -> List view */}
                    <ProtectedRoute
                      exact
                      path={`/${SERVICE_MODULE}/:entityName`}
                      moduleName={SERVICE_MODULE}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <RecordListView moduleName={SERVICE_MODULE} />
                        </Suspense>
                      }
                    />

                    {/* Service Module -> Default Detail View */}
                    <ProtectedRoute
                      exact
                      path={`/${SERVICE_MODULE}/:entityName/:recordId`}
                      moduleName={SERVICE_MODULE}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={SERVICE_MODULE}>
                            <DefaultDetailView
                              showCollaborators
                              headerExtras={[<DownloadRecordPdf />]}
                            />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                  </Switch>
                </Route>

                {/* Field Service Module */}

                <Route path="/FieldServiceModule">
                  <Switch>
                    <ProtectedRoute
                      exact
                      path={`/${FIELD_SERVICE_MODULE}/Dashboard`}
                      moduleName={FIELD_SERVICE_MODULE}
                      entityName="Dashboard"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <FieldServiceModuleDashboard />
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${FIELD_SERVICE_MODULE}/WorkOrder/:recordId`}
                      moduleName={FIELD_SERVICE_MODULE}
                      entityName="WorkOrder"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext
                            moduleName={FIELD_SERVICE_MODULE}
                            entityName={WORK_ORDER}
                          >
                            <WorkOrderDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${FIELD_SERVICE_MODULE}/Calendar`}
                      moduleName={FIELD_SERVICE_MODULE}
                      entityName="WorkOrder"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <ServiceAppointmentCalendarV2 />
                        </Suspense>
                      }
                    />

                    <ProtectedRoute
                      exact
                      path={`/${FIELD_SERVICE_MODULE}/ServiceAppointmentConfig/:recordId`}
                      moduleName={FIELD_SERVICE_MODULE}
                      entityName="ServiceAppointmentConfig"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext
                            moduleName={FIELD_SERVICE_MODULE}
                            entityName={SERVICE_APPOINTMENT_CONFIG}
                            skipLoadingUserActivity
                          >
                            <ServiceAppointmentConfigDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${FIELD_SERVICE_MODULE}/PermissionToFail/:recordId`}
                      moduleName={FIELD_SERVICE_MODULE}
                      entityName="PermissionToFail"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext
                            moduleName={FIELD_SERVICE_MODULE}
                            entityName={'PermissionToFail'}
                          >
                            <PermissionToFailDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${FIELD_SERVICE_MODULE}/WorkOrderRemediationView`}
                      moduleName={FIELD_SERVICE_MODULE}
                      entityName="WorkOrderRemediationView"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <WorkOrderRemediationView />
                        </Suspense>
                      }
                    />

                    <ProtectedRoute
                      exact
                      path={`/${FIELD_SERVICE_MODULE}/:entityName`}
                      moduleName={FIELD_SERVICE_MODULE}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <RecordListView moduleName={FIELD_SERVICE_MODULE} />
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${FIELD_SERVICE_MODULE}/:entityName/:recordId`}
                      moduleName={FIELD_SERVICE_MODULE}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={FIELD_SERVICE_MODULE}>
                            <DefaultDetailView
                              showCollaborators
                              headerExtras={[<DownloadRecordPdf />]}
                            />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    {/* Invoice Builder */}
                    <ProtectedRoute
                      exact
                      path={`/${FIELD_SERVICE_MODULE}/${WORK_ORDER}/:recordId/invoice`}
                      moduleName={FIELD_SERVICE_MODULE}
                      entityName={WORK_ORDER}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <InvoiceBuilder
                            moduleName={FIELD_SERVICE_MODULE}
                            entityName={WORK_ORDER}
                          />
                        </Suspense>
                      }
                    />
                  </Switch>
                </Route>
                <Route path="/BillingModule">
                  <Switch>
                    <ProtectedRoute
                      exact
                      path={`/${BILLING_MODULE}/Dashboard`}
                      moduleName={BILLING_MODULE}
                      entityName="Dashboard"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <BillingModuleDashboard />
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${BILLING_MODULE}/${INVOICE}/:recordId`}
                      moduleName={BILLING_MODULE}
                      entityName="Invoice"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={BILLING_MODULE} entityName={INVOICE}>
                            <InvoiceDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${BILLING_MODULE}/${TRANSACTION}/:recordId`}
                      moduleName={BILLING_MODULE}
                      entityName={TRANSACTION}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={BILLING_MODULE} entityName={TRANSACTION}>
                            <TransactionDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${BILLING_MODULE}/${PAYMENT_METHOD_REFUND}/:recordId`}
                      moduleName={BILLING_MODULE}
                      entityName={PAYMENT_METHOD_REFUND}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext
                            moduleName={BILLING_MODULE}
                            entityName={PAYMENT_METHOD_REFUND}
                          >
                            <PaymentMethodRefundDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${BILLING_MODULE}/${INVOICE_ITEM}/:recordId`}
                      moduleName={BILLING_MODULE}
                      entityName={INVOICE_ITEM}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={BILLING_MODULE} entityName={INVOICE_ITEM}>
                            <InvoiceItemDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${BILLING_MODULE}/${BILLING_REQUEST}/:recordId`}
                      moduleName={BILLING_MODULE}
                      entityName={BILLING_REQUEST}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext
                            moduleName={BILLING_MODULE}
                            entityName={BILLING_REQUEST}
                          >
                            <BillingRequestDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${BILLING_MODULE}/PaymentMethod/:recordId`}
                      moduleName={BILLING_MODULE}
                      entityName="PaymentMethod"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext
                            moduleName={BILLING_MODULE}
                            entityName={PAYMENT_METHOD}
                          >
                            <PaymentMethodDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${BILLING_MODULE}/${CREDIT_NOTE}/:recordId`}
                      moduleName={BILLING_MODULE}
                      entityName={CREDIT_NOTE}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={BILLING_MODULE} entityName={CREDIT_NOTE}>
                            <CreditNoteDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${BILLING_MODULE}/:entityName`}
                      moduleName={BILLING_MODULE}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <RecordListView moduleName={BILLING_MODULE} />
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${BILLING_MODULE}/:entityName/:recordId`}
                      moduleName={BILLING_MODULE}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={BILLING_MODULE}>
                            <DefaultDetailView showCollaborators />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                  </Switch>
                </Route>
                <Route path="/IdentityModule">
                  <IdentityModuleRoutes />
                </Route>
                <Route path="/IdentityManagerModule">
                  <IdentityManagerModuleRoutes />
                </Route>
                <Route path="/SchemaModule">
                  <Switch>
                    <ProtectedRoute
                      exact
                      path={`/${SCHEMA_MODULE}/Workflow/:recordId`}
                      moduleName={SCHEMA_MODULE}
                      entityName="Workflow"
                      component={
                        <DetailViewContext
                          moduleName={SCHEMA_MODULE}
                          entityName="Workflow"
                          skipLoadingUserActivity
                        >
                          <WorkflowDetailView />
                        </DetailViewContext>
                      }
                    />
                    <RoleBasedProtectedRoute
                      exact
                      path={`/${SCHEMA_MODULE}/Schema`}
                      moduleName={SCHEMA_MODULE}
                      component={<SchemaListView />}
                    />
                    <RoleBasedProtectedRoute
                      exact
                      path={`/${SCHEMA_MODULE}/Schema/:schemaId`}
                      moduleName={SCHEMA_MODULE}
                      component={<SchemasDetailView />}
                    />
                    {/* <RoleBasedProtectedRoute
                      exact
                      path={`/${SCHEMA_MODULE}/SchemaColumn/:schemaId/:schemaColumnId`}
                      moduleName={SCHEMA_MODULE}
                      component={<SchemaColumnDetailView />}
                    /> */}
                    <RoleBasedProtectedRoute
                      exact
                      path={`/${SCHEMA_MODULE}/SchemaAction/:schemaId/:schemaActionId`}
                      moduleName={SCHEMA_MODULE}
                      component={<SchemaActionDetailView />}
                    />
                    <RoleBasedProtectedRoute
                      exact
                      path={`/${SCHEMA_MODULE}/ViewConfiguration/:schemaId/:viewConfigurationId`}
                      moduleName={SCHEMA_MODULE}
                      component={<ViewConfigurationDetailView />}
                    />
                    <RoleBasedProtectedRoute
                      exact
                      path={`/${SCHEMA_MODULE}/File`}
                      moduleName={SCHEMA_MODULE}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <FileListFeedView moduleName={SCHEMA_MODULE} entityName={'File'} />
                        </Suspense>
                      }
                    />
                    <RoleBasedProtectedRoute
                      exact
                      path={`/${SCHEMA_MODULE}/File/:recordId`}
                      moduleName={SCHEMA_MODULE}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={SCHEMA_MODULE} entityName="File">
                            <FileDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${SCHEMA_MODULE}/:entityName`}
                      moduleName={SCHEMA_MODULE}
                      component={<RecordListView moduleName={SCHEMA_MODULE} />}
                    />
                    <ProtectedRoute
                      exact
                      path={`/${SCHEMA_MODULE}/:entityName/:recordId`}
                      moduleName={SCHEMA_MODULE}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={SCHEMA_MODULE}>
                            <DefaultDetailView showCollaborators />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                  </Switch>
                </Route>
                <Route path="/ServiceModule">
                  <ServiceModuleRoutes />
                </Route>
                <Route path="/ProjectModule">
                  <Switch>
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/Connection/RawData`}
                      moduleName={PROJECT_MODULE}
                      entityName="Connection"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <JSONGraphBrowser />
                        </Suspense>
                      }
                    />
                    {/* NETOMNIA ProjectModule Routes */}
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/Connection/:closureId?/:cableId?`}
                      moduleName={PROJECT_MODULE}
                      entityName="Connection"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <Autosplicing />
                        </Suspense>
                      }
                    />
                    ,
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/Autosplicing/:closureId?/:cableId?`}
                      moduleName={PROJECT_MODULE}
                      entityName="Map"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <Autosplicing />
                        </Suspense>
                      }
                    />
                    ,
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/VendorDashboard`}
                      moduleName={PROJECT_MODULE}
                      entityName="VendorDashboard"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <VendorDashboard />
                        </Suspense>
                      }
                    />
                    {/* END NETOMNIA ProjectModule Routes */}
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/Dashboard`}
                      moduleName={PROJECT_MODULE}
                      entityName="Dashboard"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <ProjectModuleDashboard />
                        </Suspense>
                      }
                    />
                    ,
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/Connection`}
                      moduleName={PROJECT_MODULE}
                      entityName="Connection"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <Autosplicing />
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/${REGION}/:recordId`}
                      moduleName={PROJECT_MODULE}
                      entityName="Region"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={PROJECT_MODULE} entityName={REGION}>
                            <RegionDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/FeaturePriceEntry/:recordId`}
                      moduleName={PROJECT_MODULE}
                      entityName="FeaturePriceEntry"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext
                            moduleName={PROJECT_MODULE}
                            entityName={'FeaturePriceEntry'}
                          >
                            <FeaturePriceEntryDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/Feature/:recordId`}
                      moduleName={PROJECT_MODULE}
                      entityName="Feature"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={PROJECT_MODULE} entityName={'Feature'}>
                            <FeatureDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/${EXCHANGE}/:recordId`}
                      moduleName={PROJECT_MODULE}
                      entityName="Exchange"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={PROJECT_MODULE} entityName={EXCHANGE}>
                            <ExchangeDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/${PROGRAM}/:recordId`}
                      moduleName={PROJECT_MODULE}
                      entityName="Program"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={PROJECT_MODULE} entityName={PROGRAM}>
                            <ProgramDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/${PROJECT}/:recordId`}
                      moduleName={PROJECT_MODULE}
                      entityName="Project"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={PROJECT_MODULE} entityName={PROJECT}>
                            <ProjectDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/Feature/:recordId`}
                      moduleName={PROJECT_MODULE}
                      entityName="Feature"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={PROJECT_MODULE} entityName={'Feature'}>
                            <FeatureDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/Feature/:recordId/configure-closure`}
                      moduleName={PROJECT_MODULE}
                      entityName="Feature"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={PROJECT_MODULE} entityName={'Feature'}>
                            <ClosureConfigurator />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/ChangeRequest/:recordId`}
                      moduleName={PROJECT_MODULE}
                      entityName="ChangeRequest"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={PROJECT_MODULE} entityName="ChangeRequest">
                            <DefaultDetailView showCollaborators />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/OpenreachNoi`}
                      moduleName={PROJECT_MODULE}
                      entityName="OpenreachNoi"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <Noi />
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/OpenreachInventory`}
                      moduleName={PROJECT_MODULE}
                      entityName="OpenreachInventory"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <Inventory />
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/OpenreachNetworkAdjustment/:recordId`}
                      moduleName={PROJECT_MODULE}
                      entityName="OpenreachNetworkAdjustment"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext
                            moduleName={PROJECT_MODULE}
                            entityName={'OpenreachNetworkAdjustment'}
                          >
                            <OpenreachNetworkAdjustmentDetail defaultTabKey={'OrderDetails'} />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/BuildComplete/:recordId`}
                      moduleName={PROJECT_MODULE}
                      entityName="BuildComplete"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext
                            moduleName={PROJECT_MODULE}
                            entityName={'BuildComplete'}
                          >
                            <ProjectModuleRecordDetailViewWide moduleName={PROJECT_MODULE} />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/OpenreachBuildComplete`}
                      moduleName={PROJECT_MODULE}
                      entityName="OpenreachBuildComplete"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <BulkBuildComplete />
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/CableConnection/:recordId`}
                      moduleName={PROJECT_MODULE}
                      entityName="CableConnection"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext
                            moduleName={PROJECT_MODULE}
                            entityName={'CableConnection'}
                          >
                            <ProjectModuleCableConnectionDetailViewWide />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/Map/:featureType?/:featureId?`}
                      moduleName={PROJECT_MODULE}
                      entityName="Map"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <PlanningModuleMap />
                        </Suspense>
                      }
                    />
                    {/* Build Pack -------------------------------------------------------- */}
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/BuildPack/:recordId`}
                      moduleName={PROJECT_MODULE}
                      entityName="Map"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <BuildPackV2 />
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/BuildPack/Splicing/:recordId`}
                      moduleName={PROJECT_MODULE}
                      entityName="Map"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <BuildPackV2 withSplicing />
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/ApplicationForPayment/:recordId`}
                      moduleName={PROJECT_MODULE}
                      entityName="ApplicationForPayment"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext
                            moduleName={PROJECT_MODULE}
                            entityName={'ApplicationForPayment'}
                          >
                            <ApplicationForPaymentDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/ApplicationForPayment/:recordId/approval`}
                      moduleName={PROJECT_MODULE}
                      entityName="ApplicationForPayment"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <ProjectModuleApplicationForPaymentApprovals />
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/WorkList/:recordId`}
                      moduleName={PROJECT_MODULE}
                      entityName="WorkList"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={PROJECT_MODULE} entityName={'WorkList'}>
                            <WorkListDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/PaymentNotice/:recordId`}
                      moduleName={PROJECT_MODULE}
                      entityName="PaymentNotice"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext
                            moduleName={PROJECT_MODULE}
                            entityName={'PaymentNotice'}
                          >
                            <PaymentNoticeDetailView />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/RFCManagementView`}
                      moduleName={PROJECT_MODULE}
                      entityName="RFCManagementView"
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <RFCManagementView />
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/:entityName`}
                      moduleName={PROJECT_MODULE}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <RecordListView moduleName={PROJECT_MODULE} />
                        </Suspense>
                      }
                    />
                    <ProtectedRoute
                      exact
                      path={`/${PROJECT_MODULE}/:entityName/:recordId`}
                      moduleName={PROJECT_MODULE}
                      component={
                        <Suspense fallback={<AppLoadingPage />}>
                          <DetailViewContext moduleName={PROJECT_MODULE}>
                            <DefaultDetailView showCollaborators />
                          </DetailViewContext>
                        </Suspense>
                      }
                    />
                  </Switch>
                </Route>
                <Route path="/Dashboard">
                  <Suspense fallback={<AppLoadingPage />}>
                    <DashboardModuleRoutes />
                  </Suspense>
                </Route>
                <Route path="/merge">
                  <Suspense fallback={<AppLoadingPage />}>
                    <MergeModuleRoutes />
                  </Suspense>
                </Route>
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/reset-password/:token" component={ResetPassword} />
                <Route exact path="/register/:token" component={Register} />
                <Route exact path="/register/:token/:apiToken/:contactId" component={Register} />
                <Route path="/login" exact>
                  <Login />
                </Route>
                <Route path="/login/mfa" exact>
                  <MFASettings />
                </Route>
                <Route path="/500">
                  <Error500 />
                </Route>
                <Route path="/403">
                  <Error403 />
                </Route>
                <Route>
                  <Error404 />
                </Route>
              </Switch>
            ) : (
              <Switch>
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/reset-password/:token" component={ResetPassword} />
                <Route exact path="/register/:token" component={Register} />
                <Route exact path="/register/:token/:apiToken/:contactId" component={Register} />
                <Route path="/login" exact>
                  <Login />
                </Route>
                <Route path="/login/mfa" exact>
                  <MFASettings />
                </Route>
                {/* User without Desktop Access */}
                <Route>
                  {!isUserTokenExpired() &&
                  userReducer.user?.roles?.length! > 0 &&
                  !canUserAccessDesktopApp(userReducer) ? (
                    <AppUnavailable />
                  ) : (
                    <></>
                  )}
                </Route>
                {/* User with Desktop Access */}
                <Route>
                  {!isUserTokenExpired() && canUserAccessDesktopApp(userReducer) ? (
                    <AppLoadingPage />
                  ) : (
                    <></>
                  )}
                </Route>
              </Switch>
            )}
          </Router>
        </Layout>
      </div>
    );
  }
}

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  toggleSearchVisibility: () => dispatch(toggleSearchVisibility()),
  modifyDbRecordState: (params: any) => dispatch(setDbRecordState(params)),
});

export default connect(mapState, mapDispatch)(App);
